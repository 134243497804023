import { Routes, Route } from "react-router-dom";

import { Rastreamento } from "../pages/Rastreamento";
import { Relatorio } from "../pages/Relatorio";

export const AppRoutes = () => {
	return (
		<Routes>
			<Route path="/" element={<Rastreamento />} />
			<Route path="/relatorio" element={<Relatorio />} />

			<Route path="*" element={<Rastreamento />} />
		</Routes>
	);
};
